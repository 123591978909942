import React from 'react';

const useDarkOverlay = (enable) => {
    const renderOverlay = (enable) => {
        if (process.browser) {
            let body = document.querySelector('body');
            let header = document.querySelector('header');

            if (enable) {
                body.classList.add('dark_overlay');
            } else {
                body.classList.remove('dark_overlay');
            }
        }
    };

    return <>{renderOverlay(enable)}</>;
};

export default useDarkOverlay;
